import { useTranslation } from "next-i18next"
import parse from "html-react-parser"

const useLeadGeneration = authorData => {
  const { t } = useTranslation()
  const i18n = {
    mainTitle: authorData?.formTitle
      ? parse(authorData?.formTitle.replaceAll("&amp;nbsp;", " "))
      : t("kf.leadGeneration.main.title"),
    requiredInfoText: authorData?.formDescription
      ? parse(authorData?.formDescription.replaceAll("&amp;nbsp;", " "))
      : t("kf.leadGeneration.description"),

    mobileNumber: t("kf.leadGeneration.phoneNumber"),
    name: t("kf.pdp.label.username"),
    nameError: t("kf.leadGeneration.error.name"),
    privacyText: authorData?.leadGenerationPrivacyPolicyText
      ? parse(
          authorData?.leadGenerationPrivacyPolicyText.replaceAll(
            "&amp;nbsp;",
            " "
          )
        )
      : t("kf.leadGeneration.privacyText"),
    submit: t("kf.customerSupport.label.submit"),
    cancel: t("kf.customerSupport.label.cancel"),
    messageDescriptionSuccess: t("kf.leadGeneration.messageDescriptionSuccess"),
    backToForm: t("kf.leadGeneration.backToForm"),
    errorMobileNumber: t("kf.customerSupport.error.validMobileNumber"),
  }

  return i18n
}

export default useLeadGeneration
