import { useState } from "react"
import _get from "lodash/get"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"
import Button from "@/components/core/Button/Button"
import Input from "@/components/core/Input/Input"
import { validNumber, validText } from "@/utils/validate"
import { validateField } from "@/utils/helper"
import { PHONE_REGEX } from "@/constants/index"
import { submitLeadGeneration } from "@/utils/enquiry"
import { submitLeadGenerationAnalytics } from "@/components/LeadGeneration/analytics"

const Enquiry = props => {
  const { staticText, setConfirmationSuccess } = props

  const [error, setError] = useState({})
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onNameChange = e => {
    const { value } = e.target
    if (value === "" || validText(value)) {
      setData({
        ...data,
        name: value,
      })
    }
  }
  const onBlur = field => {
    setError({
      ...error,
      [field]: validateExist(field),
    })
  }

  const validateExist = field => {
    if (field === "name") {
      if (!data[field]?.trim() || !validateField("name", data[field])) {
        return staticText?.nameError
      } else return ""
    } else if (field === "phone") {
      if (!data?.phone?.trim() || !PHONE_REGEX.test(data?.phone)) {
        return staticText?.errorMobileNumber
      }
    }
  }
  const handleSubmit = async () => {
    const errorList = getErrorList()
    setIsLoading(true)
    let errorDataList = []
    setError(errorList)
    if (errorList?.name) {
      errorDataList.push(`name`)
    }
    errorDataList.map((item, i) => {
      if (i === 0) document.getElementById(`${item}`)?.focus()
    })
    const isInvalid = Object?.values(errorList)?.find(el => el)
    if (!isInvalid) {
      const { name, phone } = data
      const payload = {
        name: name?.trim(),
        contactNumber: phone,
      }
      const response = await submitLeadGeneration(payload)
      if (response.statusCode === 200) {
        submitLeadGenerationAnalytics("success")
        setConfirmationSuccess(true)
      } else {
        submitLeadGenerationAnalytics("failure", "form submission failed")
        setConfirmationSuccess(false)
      }
      setIsLoading(false)
    } else {
      submitLeadGenerationAnalytics("failure", "form validation failed")
    }
    setIsLoading(false)
  }

  const getErrorList = () => {
    const errorList = {
      name: validateExist("name"),
      phone: validateExist("phone"),
    }
    return errorList
  }

  const isEnabled = Object?.values(getErrorList())?.find(el => el)
    ? true
    : false

  const handleChangeNumber = event => {
    const { value } = event.target
    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        phone: value,
      })
      setError({
        ...error,
        phone: "",
      })
    }
  }
  return (
    <div className="lead-generation__margin-div">
      <Input
        id="lead-generation-name"
        type="text"
        placeholder={"Name*"}
        label={"Name*"}
        value={data?.name ?? ""}
        onChange={onNameChange}
        onInput={onNameChange}
        showError={error?.name !== "" && error?.name}
        errorMessage={error?.name}
        onBlur={() => onBlur("name")}
        autoComplete={false}
        maxLength={40}
      />

      <Input
        id="lead-generation-phone"
        type="text"
        placeholder={"Phone Number*"}
        label={"Phone Number*"}
        value={data?.phone ?? ""}
        maxLength={10}
        autoComplete={false}
        onChange={handleChangeNumber}
        showError={error?.phone}
        errorMessage={error?.phone}
        onBlur={() => onBlur("phone")}
        prefix={<img src={"/icons/phone-icon.svg"} alt="" aria-hidden="true" />}
      />

      <div className="lead-generation__buttonDiv">
        <Button
          type="black"
          role="button"
          label={staticText.submit}
          className="lead-generation__button"
          flexible
          onClick={handleSubmit}
          disabled={isEnabled}
          loading={isLoading}
        />
      </div>
      <div className="lead-generation__privacyText">
        {staticText?.privacyText}
      </div>
    </div>
  )
}

export default Enquiry
