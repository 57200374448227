import { useEffect, useState, useRef } from "react"
import styles from "@/components/LeadGeneration/LeadGenerationView/index.module.scss"
import useLeadGeneration from "@/i18n/useLeadGenerationi18n"
import Confirmation from "@/components/LeadGeneration/Confirmation"
import Enquiry from "@/components/LeadGeneration/Form/Enquiry"

const LeadGenerationView = props => {
  const { data: authorData = {} } = props
  const confirmationRef = useRef(null)
  const staticText = useLeadGeneration(authorData)
  const [confirmationSuccess, setConfirmationSuccess] = useState(null)

  return (
    <>
      {!confirmationSuccess && (
        <div className={styles.leadGenerationLanding}>
          <div className="lead-generation">
            <div className="lead-generation__title">
              <div className="lead-generation__heading">
                {staticText.mainTitle}
              </div>
              <span className="lead-generation__required-fields">
                {staticText.requiredInfoText}
              </span>
            </div>
            <Enquiry
              staticText={staticText}
              setConfirmationSuccess={setConfirmationSuccess}
            />
          </div>
        </div>
      )}
      {confirmationSuccess && (
        <div ref={confirmationRef} className={styles.leadGenerationLanding}>
          <div className="lead-generation">
            <Confirmation
              staticText={staticText}
              setConfirmationSuccess={setConfirmationSuccess}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default LeadGenerationView
